import React from 'react';
import Hero from '../components/Hero/Hero';


function Resume() {
  return (
    <>
    <Hero />

    <h1> Nikhil Datar: Current Resume</h1>
    </>

  )
}

export default Resume